/* eslint-disable max-len */
/* eslint-disable no-tabs */
$(() => {
	const Login = (() => ({
		mode: 'user',
		animation: null,
		duration: 10000,
		refreshBarcode() {
			const me = this;
			const thn = $('#select-tahun :selected').val() ?? new Date().getFullYear();
			const url = `${baseURL}auth/qrlogin?tahun=${thn}&_dc=${Math.random()}`;
			if (me.animation != null) {
				me.animation.stop(true, false);
			}
			if ($('#qr-login').is(':visible')) {
				$.ajax({
					type: 'POST',
					url: `${baseURL}auth/isqrlogin`,
					success(res) {
						if (res?.isLogin) {
							$('#form-username').val(res.user.username);
							$('#form-password').val(res.user.password);
							$('#form-login').submit();
						} else {
							$('#qr-login').attr('src', url);
						}
					},
				});
			}
		},
		showMessage(mode, params) {
			// eslint-disable-next-line no-undef
			window.stack = new PNotify.Stack({
				dir1: 'down',
				dir2: 'left',
				firstpos1: 20,
				firstpos2: 20,
				maxStrategy: 'close',
			});
			window.stack.forEach((notif) => {
				notif.close();
			});
			// eslint-disable-next-line no-undef
			PNotify[mode]({
				sticker: false,
				closerHover: false,
				delay: 2000,
				stack: window.stack,
				...params,
			});
		},
		loadWebPublik() {
			$.ajax({
				type: 'POST',
				url: `${baseURL}front/menu`,
				success(res) {
					if (res?.success) {
						res.menu.forEach((item) => {
							const $app = $(`
                            <div class="app-menu d-flex flex-column p-2" role="button" 
                            title="${item.nama}"  data-toggle="tooltip"
                            data-link="https://bmd.jakarta.go.id/v2">
                                <div class="flex-fill w-100">
                                </div><span></span>
                            </div>`);
							$app.find('span').text(item.nama);
							$app.find('.flex-fill').css({
								background: `rgba(0, 0, 0, 0) url(${item.icon}) no-repeat scroll center center`,
								backgroundSize: '64px 64px',
							});
							$app.click(() => {
								window.open(item.link);
							});
							$('#list-wp').append($app);
						});
					}
				},
			});
		},
		start() {
			const me = this;
			$('#qr-login').on('load', () => {
				$('#loading-bar').width('0%');
				me.animation = $('#loading-bar').animate({ width: '100%' }, me.duration, me.refreshBarcode);
			});
			$('#select-tahun').change((e) => {
				e.preventDefault();
				me.refreshBarcode();
			});
			$('.toogleMode').click((e) => {
				e.preventDefault();
				const isQrLogin = me.mode === 'qrcode';
				$('.toogleMode').html(`Login menggunakan ${!isQrLogin ? 'Username' : 'Mobile Aset'}`);
				$('.userpass')[me.mode === 'user' ? 'addClass' : 'removeClass']('d-none');
				$('.qrcode')[me.mode !== 'user' ? 'addClass' : 'removeClass']('d-none');
				me.mode = me.mode === 'user' ? 'qrcode' : 'user';
				me.refreshBarcode();
			});
			$('.qrcode').addClass('d-none');
			$('#form-login').ajaxForm({
				url: `${baseURL}auth/login`,
				type: 'post',
				novalidate: true,
				beforeSubmit() {
					$('#btn-submit').attr('disabled', true).html($('#btn-submit').data('text-loading'));
				},
				success(res) {
					$('#btn-submit').attr('disabled', false).html($('#btn-submit').data('text-enable'));
					if (res?.login && res?.redirect)window.location.href = res?.redirect;
					else if (res?.login) window.location.reload();
					else {
						me.showMessage('info', {
							title: 'Error',
							text: res.message,
							delay: 5000,
						});
						if (me.mode === 'qrcode') {
							const thn = $('#select-tahun :selected').val() ?? new Date().getFullYear();
							const url = `${baseURL}auth/qrlogin?tahun=${thn}&_dc=${Math.random()}`;
							$('#qr-login').attr('src', url);
						}
					}
				},
			});
			$('#show-pasword').click((e) => {
				$(e.target).toggleClass('fa-eye-slash fa-eye');
				const el = $(e.target).closest('div').find('input');
				el.attr('type', el.attr('type') === 'text' ? 'password' : 'text');
			});
			me.loadWebPublik();
		},
	}))();
	Login.start();
});
